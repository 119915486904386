.reviewsCounter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--space-4);
    inline-size: fit-content;
    color: var(--display-onlight-secondary);

    border-radius: var(--border-radius-base);
    border: 1px solid var(--box-outline-border);
    padding-inline: var(--space-4);

    > div:has(svg) {
        display: flex;
        align-items: center;
        padding: var(--space-8) var(--space-8) var(--space-8) var(--space-4);
        border-right: 1px solid var(--box-outline-border);
    }
}
